import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Terrain from '../../assets/images/terrain.png'
import "./style.css"

export const AboutUs = () => {
    return (
        <Container>
            <Row>
                <Col md="5" xs="12" className="about-us">
                    <div className="about-us-text">
                        <p><h4>about us</h4></p>
                        <div style={{ fontStyle: "italic" }}>
                            <p>anti green washing</p>
                            <p>anti tchotchke</p>
                            <p>pro community</p>
                            <div className="my-4">
                                <p>with a background in architectural design, we know the time and care it takes to curate a speciality  product for the individual customer, and the significance of these efforts. we want to showcase the existence of that process in all creative industries, to highlight the relationship between producer and consumer, and give the producers a platform to curate those realtionships </p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md="7" xs="12">
                    <img style={{ width: "100%" }} src={Terrain} alt="terrain" className="img-fluid" />
                </Col>
            </Row>
        </Container>
    )
}