import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Home } from './pages/home';
import { Artists } from './pages/artists';
import { Artist } from './pages/artist';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/artists" Component={Artists} />
        <Route path="/artist" Component={Artist} />
      </Routes>

    </Router>
  );
}

export default App;
