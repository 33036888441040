import React from 'react'
import { Col, Row } from 'react-bootstrap'
import "./style.css"

export const Footer = () => {
    return (
        <div>
            <div className="footer" style={{ color: "white", display: "flex" }}>
                <div style={{ background: "#4C7F40", width: "20%" }}>
                    <h2>Las Vegas, Nevada</h2>
                </div>
                <div style={{ background: "rgba(145, 209, 255, 0.47)", width: "65%" }}>
                    <h2><a style={{ color: "white" }} href="tel:7027260177">+1 (702) 726-3223</a></h2>
                </div>
                <div style={{ background: "#BBAA92", width: "15%" }}>
                    <h2>Survey</h2>
                </div>
            </div>
            <Row className="footer" style={{ color: "white" }}>
                <Col md="9" style={{ background: "#7F4062" }}>
                    <h2>Ancient Environmentally Aware Methods</h2>
                </Col>
                <Col md="3" style={{ background: "#16105A" }}>
                    <h2>Join Us</h2>
                </Col>
            </Row>
            <Row className="footer" style={{ color: "white" }}>
                <Col md="2" style={{ background: "black" }}>
                    <h2>gobekli</h2>
                </Col>
                <Col md="8" style={{ background: "#16105A" }}>
                    <h2>Terms of Use</h2>
                </Col>
                <Col md="2" style={{ background: "rgba(223, 219, 116, 0.30)" }}>
                    <h2>Privacy</h2>
                </Col>
            </Row>
        </div>
    )
}