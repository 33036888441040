import React from 'react'
import { Image } from 'react-bootstrap'
import { Artist } from '../../models'
import "./style.css"
import { useNavigate } from 'react-router-dom'

type ISpotlight = {
    artist: Artist
}

export const Spotlight = ({ artist }: ISpotlight) => {
    const navigate = useNavigate()

    return (
        <div onClick={() => {
            navigate("/artist")
        }} className="artist">
            <div>
                <Image className="artist-image" src={artist.image} alt="artist" width="100%" />
            </div>
            <h3 className="mt-3">
                {artist.name}
            </h3>
            {
                artist.keywords.map((keyword, index) => (
                    <span key={index} className="keywords badge bg-light text-dark me-2 my-1">{keyword}</span>
                ))
            }
        </div>
    )
}