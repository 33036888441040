import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Artist } from '../../models'
import "./style.css"
import { Spotlight } from '../../components/spotlight'
import { Header } from '../../components/header'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'

const artists: Artist[] = [
    {
        name: "studio studio inc.",
        image: "/images/unsplash/furniture1.jpg",
        keywords: ["california", "usa", "lighting", "objects", "rugs", "tableware", "vessels", "furniture", "design", "interior"]
    },
    {
        name: "modern design co.",
        image: "/images/unsplash/furniture2.jpg",
        keywords: ["modern", "minimalist", "furniture", "design", "interior", "decor"]
    },
    {
        name: "artisan furniture",
        image: "/images/unsplash/furniture3.jpg",
        keywords: ["handmade", "wood", "furniture", "craftsmanship", "design", "interior"]
    },
    {
        name: "eco home",
        image: "/images/unsplash/furniture4.jpg",
        keywords: ["sustainable", "eco-friendly", "furniture", "design", "interior", "green"]
    },
    {
        name: "urban living",
        image: "/images/unsplash/furniture5.jpg",
        keywords: ["urban", "modern", "furniture", "design", "interior", "city"]
    },
    {
        name: "rustic charm",
        image: "/images/unsplash/furniture6.jpg",
        keywords: ["rustic", "wood", "furniture", "design", "interior", "country"]
    },
    {
        name: "vintage vibes",
        image: "/images/unsplash/furniture7.jpg",
        keywords: ["vintage", "retro", "furniture", "design", "interior", "classic"]
    },
    {
        name: "luxury living",
        image: "/images/unsplash/furniture8.jpg",
        keywords: ["luxury", "high-end", "furniture", "design", "interior", "elegant"]
    },
    {
        name: "coastal living",
        image: "/images/unsplash/furniture9.jpg",
        keywords: ["coastal", "beach", "furniture", "design", "interior", "relax"]
    },
    {
        name: "boho chic",
        image: "/images/unsplash/furniture10.jpg",
        keywords: ["bohemian", "chic", "furniture", "design", "interior", "colorful"]
    },
    {
        name: "industrial design",
        image: "/images/unsplash/furniture11.jpg",
        keywords: ["industrial", "metal", "furniture", "design", "interior", "urban"]
    },
    {
        name: "scandinavian style",
        image: "/images/unsplash/furniture12.jpg",
        keywords: ["scandinavian", "minimalist", "furniture", "design", "interior", "clean"]
    },
    {
        name: "mid-century modern",
        image: "/images/unsplash/furniture13.jpg",
        keywords: ["mid-century", "modern", "furniture", "design", "interior", "retro"]
    },
    {
        name: "farmhouse finds",
        image: "/images/unsplash/furniture14.jpg",
        keywords: ["farmhouse", "rustic", "furniture", "design", "interior", "country"]
    },
    {
        name: "contemporary creations",
        image: "/images/unsplash/furniture15.jpg",
        keywords: ["contemporary", "modern", "furniture", "design", "interior", "sleek"]
    },
    {
        name: "classic elegance",
        image: "/images/unsplash/furniture16.jpg",
        keywords: ["classic", "elegant", "furniture", "design", "interior", "timeless"]
    }
]

export const Artists = () => {

    return (
        <Container>
            <Header />
            <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 780: 2, 1200: 3 }}
            >
                <Masonry>
                    {
                        artists.map((artist, index) => (
                            <Spotlight artist={artist} key={index} />
                        ))
                    }
                </Masonry>

            </ResponsiveMasonry>
        </Container>
    )
}