import React from 'react'
import { Cell } from '../cell'
import { Container } from 'react-bootstrap'
import "./style.css"
import { useNavigate } from 'react-router-dom'

type CellProps = {
    image: string,
    title: string,
    subtitle: string
}

export const CellLayout = (props: {
    cellProps: CellProps[],
}) => {
    const navigate = useNavigate()

    return (
        <Container className="cell-layout">
            <div className="grid-container">
                <div className="text-content subtitle grid-item-span">
                    TO MINIMIZE CARBON FOOTPRINT WE PRIORITIZE LOCATION BASED SHOPPING AND CREATIVE COMMUNITIES FIRST
                </div>
                {props.cellProps.map((cell, index) => (
                    <div className="grid-item" key={index}>
                        <Cell onClick={() => {
                            navigate("/artists")
                        }} image={cell.image} title={cell.title} subtitle={cell.subtitle} />
                    </div>
                ))}
            </div>
        </Container>
    )
}