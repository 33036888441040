import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import LandFill from '../../assets/images/land-fill.png'
import Factory from '../../assets/images/factory.png'
import PoorArea from '../../assets/images/poor-area.png'
import "./style.css"

export const Manifesto = () => {
    return (
        <Container className="my-5">
            <Row>
                <Col md="6">
                    <Image src={LandFill} fluid />
                    <div className="factory">
                        <Image src={Factory} fluid />
                    </div>
                    <div className="poor">
                        <Image src={PoorArea} fluid />
                    </div>
                </Col>
                <Col md="6" sm="12" className="manifesto">
                    <h3 className='my-3' style={{ textAlign: "center" }}>
                        manifesto
                    </h3>
                    <p style={{ textAlign: "left" }}>
                        What we see of the post industrialism world today, is entirely disconnected from what we saw in the original values of a early artisanal society. It began as people exchanging goods and services, the workmanship and craftsmanship of the individual complementing the efforts of others and completing a community of producers. An important component always being that each individual felt validated and necessary as part of the exchange process and key element was camaraderie and community. Fast forward a few hundred years or a few thousand depending on which key point and we have globalized organizations that have made exchange easier than ever, but have entirely and wholly removed the interpersonal aspect that made these interactions not just make sense, but also made them beautiful. Our goal is to reintroduce this long lost (ie. manufactured out of this wonderfully human system as it was viewed as inefficient) human aspect of exchange of goods and services by reconnecting people within a locality. Breeding a traditional means of exchange but more importantly, fostering the real human connections originally held around the exchange and sharing of our personal crafts. The sharing of one’s personal act of craftsmanship (and craftswomanship) was a baring of one’s soul because these things were peoples occupations and profound expressions of self. With a reintroduction of these things we can in a spirited effort, attempt to return to these time tested means of exchange.
                    </p>
                </Col>
            </Row>
        </Container>
    )
}