import React from 'react'
import { Container, Image, Navbar, Row } from 'react-bootstrap'
import Logo from '../../assets/images/gobekli.png'
import './style.css'

export const Header = () => {
    return (
        <Navbar>
            <Container>
                <div className="title-container box">
                    <Navbar.Brand href="/"><Image src={Logo} /></Navbar.Brand>
                    <div className="subtitle pt-3">CRAFTMANSHIP, CARE AND CONNECTION</div>
                </div>

                {/* Right Side (Button) */}
                <div className="button-container">
                    <button className="custom-button">IN A QUANDARY?</button>
                </div>
            </Container>
        </Navbar>
    )
}