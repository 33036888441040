import React from 'react'
import { Container, Image, } from 'react-bootstrap'
import FrontPageMain from '../../assets/images/front-page-main.png'
import './style.css'

export const Main = () => {
    return (
        <Container className="pt-5">
            <div className="main-image centered" >
                <Image style={{ width: "100%", maxWidth: 640 }} src={FrontPageMain} />
            </div >
            <div style={{ display: "block" }} className="centered">
                <p className="main pt-4" style={{ fontSize: 19 }}>how <strong>YOU</strong> can help US</p>
                <div className="cursive">
                    <p>shop local,</p>
                    <p>shop artisanal,</p>
                    <p>shop us around,</p>
                    <p>share us around,</p>
                    <p>join us and share your craft,</p>
                    <p>you can help change the modern paradigm of the customer producer experience.</p>
                    <p>the more number of producers we have, we can help the planet by producting CONSCIOUSLY and intentionally, rather than haphazardly</p>
                </div>
            </div>
        </Container>
    )
}